.main {
  width: 1500px;
}

.checkbox {
  background: white;
  color: black;
  border: none;
}

.invisible {
  display: none;
}
.previousDeployment {
  padding: 4px;
  width: 100%;
  height: 200px;
  background: lightgray;
  white-space: normal;
}

.latestCommit {
  padding: 4px;
  width: 100%;
  height: 180px;
  background: lightcyan;
  white-space: normal;
}

.buildSuccess {
  padding: 4px;
  width: 100%;
  height: 350px;
  background: lightgreen;
  white-space: normal;
}

.buildSuccessDM {
  padding: 4px;
  width: 100%;
  height: 150px;
  background: lightgreen;
  white-space: normal;
}

.buildFailure {
  padding: 4px;
  width: 100%;
  height: 280px;
  background: lightcoral;
  font-weight: bold;
  white-space: normal;
}

.buildFailureDM {
  padding: 4px;
  width: 100%;
  height: 120px;
  background: lightcoral;
  font-weight: bold;
  white-space: normal;
}

.buildLoading {
  padding: 4px;
  width: 100%;
  height: 280px;
  background: yellow;
  white-space: normal;
}

.webfailed {
  background: lightcoral;
}

.webloading {
  background: yellow;
}
