.ContentSection {
  margin-left: 20px;
  float: left;
  width: 1200px;
}

.TableCellSmallFont {
  font-size: small;
}

.LabelColumn {
  font-weight: bold;
}

.MainButton {
  font-size: small;
  background-color: grey;
  color: white;
  border: black;
}
