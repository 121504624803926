@media all and (min-width: 480px) {
  .Login {
    padding: 20px 0;
  }

  .Login form {
    margin-bottom:5px;
    max-width: 320px;
    font-weight: bold;
  }

  .control
  {
    margin-top: 20px;
  }
}
