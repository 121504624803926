.App {
  margin-top: 25px;
  margin-left: 25px;
  margin-right: 0px;
  width: 100%;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .navbar {
  background-color: rgb(1, 156, 249);
  color: white;
  width: 1400px;
  font-weight: bold;
}

.App .navbar-default .navbar-nav > li > a {
  color: white;
}

.sidenav {
  float: left;
  background-color: rgb(221, 221, 223);
  width: 150px;
  height: 800px;
}

.sidenav .nav a {
  color: rgb(33, 33, 36);
  margin-left: 5px;
  margin-top: 5px;
}

.contentSection {
  float: left;
  margin-top: 20px;
  margin-left: 20px;
}

.leftColumn {
  float: left;
  margin-left: 10px;
}

.rightColumn {
  margin-left: 150px;
  float: left;
}
